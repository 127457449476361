import '../../Style/App.css';
import React from 'react';

class KeyboardLayout extends React.Component {

  render() {
    let theme = this.props.isDarkMode ? 'text-white bg-gray-500' : 'text-black bg-gray-300';
    return (
        <div className='h-[25%] w-[23%] min-w-[380px] mx-auto text-sm font-bold'>
          <div className="h-1/3 w-full py-1 grid grid-cols-10 2xsm:gap-1 xsm:gap-2">
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='Q'>Q</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='W'>W</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='E'>E</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='R'>R</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='T'>T</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='Y'>Y</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500 select-none notranslate`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='U'>U</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500 select-none notranslate`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='I'>I</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='O'>O</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='P'>P</button>
          </div>

          <div className="h-1/3 w-[92%] py-1 mx-auto grid grid-cols-9 2xsm:gap-1 xsm:gap-2">
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='A'>A</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='S'>S</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='D'>D</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='F'>F</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='G'>G</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='H'>H</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='J'>J</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='K'>K</button>
            <button className={`rounded-lg ${theme} hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='L'>L</button>
          </div>

          <div className="h-1/3 w-full py-1 mx-auto grid grid-cols-11 2xsm:gap-1 xsm:gap-2">
            <button className={`rounded-lg ${theme} col-span-2 hover:font-black transition duration-500 select-none notranslate`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='ENTER'>ENTER</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='Z'>Z</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='X'>X</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='C'>C</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='V'>V</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='B'>B</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='N'>N</button>
            <button className={`rounded-lg ${theme} col-span-1 hover:font-black transition duration-500`} onClick={(e) => {this.props.onButtonClick(e.target.innerHTML)}} id='M'>M</button>
            <button className={`rounded-lg ${theme} col-span-2 hover:font-black transition duration-500 select-none notranslate`}  onClick={(e) => {this.props.onButtonClick('DELETE')}} id='DELETE'>DELETE</button>
          </div>
        </div>
    );
  }

  componentDidUpdate() {
    this.setKeyboardBackground();
  }

  componentDidMount() {
    this.setKeyboardBackground();
  }

  setKeyboardBackground() {
    if(this.props.completed === 'true') {
      let buttons = document.querySelectorAll('button');
      for (let i = 0; i < buttons.length; i++) {
          let button = buttons[i];
          button.disabled = true;
          button.className = "rounded-lg bg-gray-500 col-span-1 text-white";
      }
      let enterButton = document.getElementById('ENTER');
      enterButton.disabled = true;
      enterButton.className = "rounded-lg bg-gray-500 col-span-2 text-white";
      let deleteButton = document.getElementById('DELETE');
      deleteButton.disabled = true;
      deleteButton.className = "rounded-lg bg-gray-500 col-span-2 text-white";
    } else {
      for (let i = 0; i < this.props.inputRow; i++) {
        for (let j = 0; j < this.props.rows[i].length; j++) {
          if(this.props.answerWord.charAt(j) === this.props.rows[i][j]) {
            let button = document.getElementById(this.props.rows[i][j]);
            button.className = "rounded-lg bg-green col-span-1 text-white hover:font-black transition duration-500";
          } else if(this.props.answerWord.includes(this.props.rows[i][j])) {
            let button = document.getElementById(this.props.rows[i][j]);
            if(!button.className.includes('bg-green'))
              button.className = "rounded-lg bg-orange col-span-1 text-white hover:font-black transition duration-500";
          } else {
            let button = document.getElementById(this.props.rows[i][j]);
            //button.disabled = true;
            let theme = this.props.isDarkMode ? 'bg-gray-700' : 'bg-gray-500';
            button.className = `rounded-lg ${theme} col-span-1 text-white hover:font-black transition duration-500`;
          }
        }
      }
    }
  }

}
export default KeyboardLayout;

import FloatingButton from './FloatingButton';

function PrivacyPolicyAndTermsOfUse(props) {
    return (
        <div className={props.isDarkMode ? 'bg-darkmode text-white pt-4 px-8 text-left' : 'pt-4 px-8 text-left'}>
            <PrivacyPolicy />
            <br /><br />
            <TermsOfUse />
            <FloatingButton closePrivacyPolicyAndTermsOfUserPage={props.closePrivacyPolicyAndTermsOfUserPage}/>
        </div>
    );
}

const PrivacyPolicy = () => {
    return (
        <div>
            <p className='text-2xl font-bold pb-1 underline underline-offset-4'>Privacy Policy</p>
            <p>
                This Privacy Policy describes how our synonym game ("the Game") collects, uses, and protects the personal information you provide while using the Game. We are committed to ensuring that your privacy is protected and that any information you provide is used in accordance with this Privacy Policy.
            </p>
            <ol>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Information Collection and Use</h2>
                    <ul>
                        <li>We do not collect any personally identifiable information from users of the Game.</li>
                        <li>We may collect non-personal information such as anonymized usage data to improve the Game's performance and user experience.</li>
                    </ul>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Third-Party Services</h2>
                    <p>The Game may include links or integrations with third-party services or websites. Please note that these third parties have their own privacy policies and terms of use, and we do not have control over their practices.</p>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Donations</h2>
                    <ul>
                        <li>The Game accepts donations to support its development and maintenance.</li>
                        <li>We may collect and store certain personal information, such as name, email address, and donation amount, to process and acknowledge your donation. This information will be kept confidential and will not be shared with any third parties, except as required by law.</li>
                    </ul>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Security</h2>
                    <p>We are committed to ensuring that your information is secure. We have implemented reasonable security measures to protect against unauthorized access, disclosure, alteration, or destruction of your personal information.</p>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Changes to the Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time. Any changes will be effective when we post the revised Privacy Policy on this page.</p>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at synordle.game@gmail.com.</p>
                </li>
            </ol>
        </div>
    );
};

const TermsOfUse = () => {
    return (
        <div className='pb-4'>
            <h1 className='text-2xl font-bold pb-4 underline underline-offset-4'>Terms of Use</h1>
            <ol>
                <li>
                    <h2 className='text-lg font-bold pb-1'>Intellectual Property</h2>
                    <p>
                        The Game and all related content, including but not limited to text, graphics, logos, and images, are the intellectual property of the Game developer or its licensors. You may not use, reproduce, or distribute any of the content without prior written permission.
                    </p>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Donations</h2>
                    <p>
                        The Game accepts donations to support its development and maintenance. By making a donation, you acknowledge that:
                    </p>
                    <ul>
                        <li>Donations are voluntary and non-refundable.</li>
                        <li>You are the rightful owner of the funds used for the donation and have obtained them lawfully.</li>
                        <li>You will not use any fraudulent or unauthorized means to make a donation.</li>
                        <li>You will not expect any goods, services, or benefits in return for your donation, except as explicitly stated by the Game.</li>
                    </ul>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Disclaimer of Warranty</h2>
                    <p>
                        The Game is provided on an "as is" basis without warranties of any kind, whether expressed or implied. We do not guarantee that the Game will be error-free or uninterrupted, or that any defects will be corrected. You acknowledge that your use of the Game is at your own discretion and risk.
                    </p>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Limitation of Liability</h2>
                    <p>
                        In no event shall the Game developer or its affiliates be liable for any indirect, consequential, incidental, or punitive damages arising out of or in connection with your use of the Game.
                    </p>
                </li>
                <li>
                    <h2 className='text-lg font-bold pb-1 pt-2'>Indemnification</h2>
                    <p>
                        You agree to indemnify and hold the Game developer harmless from any claims, damages, losses, liabilities, or expenses (including legal fees) arising out of your use of the Game.
                    </p>
                </li>
            </ol>
        </div>
    );
};

export default PrivacyPolicyAndTermsOfUse;
import React from 'react';
import Confetti from 'react-confetti';
import InputGrid from './InputGrid';
import KeyboardLayout from './KeyboardLayout';
import HintWord from './HintWord';
import GameOverModal from './GameOverModal';
import '../../Style/shake.css';
import ErrorComponent from './ErrorComponent';

class Body extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      fiveLetterWords: [],
      synonym: '',
      definition: '',
      exampleSentence: '',
      answerWord: '',
      completed: 'false',
      synordleDate: new Date().toJSON().slice(0, 10),
      clickedButtonContent: '',
      rowCount: 5,
      columnCount: 5,
      rows: Array(5).fill(null).map(() => Array(5)),
      lettersThatDoesntExist: [],
      lettersThatAreAtTheWrongPosition: [],
      lettersThatAreAtTheCorrectPosition: [],
      inputRow: 0,
      inputColumn: 0,
      width: 0,
      height: 0,
      showError: false,
      isShaking: false,
      isModalOpen: false,
      isGameOverModalOpen: true
    };
  }

  render() {
    let hasWon = this.state.completed === 'true' && this.state.answerWord === this.state.rows[this.state.inputRow - 1].join('');
    return (
      <div className='h-[84%] w-full pt-2 flex flex-col justify-center'>
        {hasWon ? <Confetti className='w-full h-full' /> : null}
        <HintWord synonym={this.state.synonym} exampleSentence={this.state.exampleSentence} definition={this.state.definition} isDarkMode={this.props.isDarkMode} />
        <InputGrid answerWord={this.state.answerWord}
          rows={this.state.rows}
          inputRow={this.state.inputRow} isShaking={this.state.isShaking}
          isDarkMode={this.props.isDarkMode}
        />
        <ErrorComponent showError={this.state.showError} />
        {this.state.completed === 'true' && !hasWon && this.state.isGameOverModalOpen ? <GameOverModal answer={this.state.answerWord} onClose={this.handleGameOverModalClose} isDarkMode={this.props.isDarkMode} /> : null}
        <KeyboardLayout onButtonClick={(buttonContent) => this.onButtonClick(buttonContent)} isDarkMode={this.props.isDarkMode}
          answerWord={this.state.answerWord}
          rows={this.state.rows}
          inputRow={this.state.inputRow}
          completed={this.state.completed} />
      </div>
    );
  }

  handleGameOverModalClose = () => {
    this.setState({ isGameOverModalOpen: false });
  }

  async componentDidMount() {
    let rows = this.state.rows;
    let synordleDate = localStorage.getItem('synordleDate');
    if (synordleDate !== null) {
      if (synordleDate !== this.state.synordleDate) {
        localStorage.removeItem('guess0');
        localStorage.removeItem('guess1');
        localStorage.removeItem('guess2');
        localStorage.removeItem('guess3');
        localStorage.removeItem('guess4');
        localStorage.removeItem('inputRow');
        localStorage.removeItem('completed');
        this.setState({ rows: Array(this.state.rowCount).fill(null).map(() => Array(this.state.columnCount)) });
        this.setState({ inputRow: 0 });
        this.setState({ completed: 'false' });
        localStorage.setItem('synordleDate', this.state.synordleDate);
      } else {
        let guess0 = localStorage.getItem('guess0');
        if (guess0 !== null) {
          rows[0] = guess0.replace('["', '').replace('"]', '').split('","');
        }
        let guess1 = localStorage.getItem('guess1');
        if (guess1 !== null) {
          rows[1] = guess1.replace('["', '').replace('"]', '').split('","');
        }
        let guess2 = localStorage.getItem('guess2');
        if (guess2 !== null) {
          rows[2] = guess2.replace('["', '').replace('"]', '').split('","');
        }
        let guess3 = localStorage.getItem('guess3');
        if (guess3 !== null) {
          rows[3] = guess3.replace('["', '').replace('"]', '').split('","');
        }
        let guess4 = localStorage.getItem('guess4');
        if (guess4 !== null) {
          rows[4] = guess4.replace('["', '').replace('"]', '').split('","');
        }
        let inputRow = localStorage.getItem('inputRow');
        if (inputRow !== null) {
          this.setState({ inputRow: Number(inputRow) });
        }
        let completed = localStorage.getItem('completed');
        if (completed !== null) {
          this.setState({ completed: completed });
        }
      }
    } else {
      localStorage.setItem('synordleDate', this.state.synordleDate);
    }
    this.setState({ rows: rows });
    let currentDate = new Date().toJSON().slice(0, 10);
    const response = await fetch(`${process.env.PUBLIC_URL}/data.json`);
    const json = await response.json();
    this.setState({ synonym: json[currentDate].synonym.toUpperCase() });
    this.setState({ answerWord: json[currentDate].word.toUpperCase() });
    this.setState({ definition: json[currentDate].definition });
    this.setState({ exampleSentence: json[currentDate].sentence.toUpperCase() });

    this.loadWordsWith5Letters();

  }

  async loadWordsWith5Letters() {
    const response = await fetch(`${process.env.PUBLIC_URL}/5letterwords.json`);
    const dictionary = await response.json();
    this.setState({ fiveLetterWords: dictionary });
  }

  handleShake = () => {
    this.setState({ isShaking: true });
    setTimeout(() => this.setState({ isShaking: false }), 3000);
  };

  formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let result = yyyy + '-' + mm + '-' + dd;
    return result;
  }

  incrementDate(date) {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    return result;
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onButtonClick = (buttonContent) => {
    if (this.state.completed === 'true')
      return;
    if (buttonContent === "ENTER") {
      let currentAnswer = this.state.rows[this.state.inputRow].join('');
      if (this.state.fiveLetterWords[currentAnswer.toLowerCase()] === 1 || this.state.answerWord === currentAnswer) {
        if (this.state.rows[this.state.inputRow][this.state.columnCount - 1] !== null && this.state.rows[this.state.inputRow][this.state.columnCount - 1] !== undefined) {
          localStorage.setItem('inputRow', this.state.inputRow + 1);
          if (this.state.answerWord === currentAnswer || this.state.inputRow === this.state.rowCount - 1) {
            localStorage.setItem('completed', 'true');
            this.setState({ completed: 'true' });
          }
          this.setState({ inputRow: this.state.inputRow + 1 });
          this.setState({ inputColumn: 0 });
          let rows = this.state.rows;
          this.setState({ rows: rows });
          localStorage.setItem('guess' + this.state.inputRow, JSON.stringify(this.state.rows[this.state.inputRow]));
        }
      } else {
        if (currentAnswer.length !== 5) {
          this.setState({ isShaking: true });
          setTimeout(() => this.setState({ isShaking: false }), 1000);
        } else {
          this.setState({ showError: true });
          setTimeout(() => {
            this.setState({ showError: false });
          }, 3000);
        }
      }
    } else if (buttonContent === "DELETE") {
      this.handleDelete();
    } else {
      this.handleLetterClick(buttonContent);
    }
    this.setState({ clickedButtonContent: buttonContent });
  }

  handleDelete() {
    if (this.state.inputRow === this.state.rowCount)
      return;
    let rows = this.state.rows;
    rows[this.state.inputRow][this.state.inputColumn - 1] = null;
    this.setState({ rows: rows });
    if (this.state.inputColumn !== 0) {
      this.setState({ inputColumn: this.state.inputColumn - 1 });
    }
  }

  handleLetterClick(buttonContent) {
    if (this.state.inputRow === this.state.rowCount || this.state.inputColumn === this.state.columnCount)
      return;
    let rows = this.state.rows;
    rows[this.state.inputRow][this.state.inputColumn] = buttonContent
    this.setState({ rows: rows });
    this.setState({ inputColumn: this.state.inputColumn + 1 });
  }

  setInputRow(inputRow) {
    this.setState({ inputRow: inputRow })
  }

  setInputColumn(inputColumn) {
    this.setState({ inputColumn: inputColumn })
  }

}


export default Body;

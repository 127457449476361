import React from 'react';

class GameOverModal extends React.Component {
    handleOverlayClick = (event) => {
        // Only close the modal if the user clicked on the overlay, not on the modal content.
        if (event.target === event.currentTarget) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <div className="fixed inset-0 z-10 flex items-center justify-center text-black">
                <div className="absolute inset-0 bg-gray-500 opacity-75 z-40" onClick={this.handleOverlayClick} />
                <div className={this.props.isDarkMode ? "w-[20%] h-[30%] min-h-[300px] min-w-[250px] bg-darkmode text-white rounded-lg px-2 py-2 z-50 divide-y" :
                    "w-[20%] h-[30%] min-h-[300px] min-w-[250px] bg-white rounded-lg px-2 py-2 z-50 divide-y"}>
                    <div className='h-[25%] flex'>
                        <div className="w-[10%]" />
                        <div className='w-[80%] h-full'>
                            <h1 className="w-full text-xl font-bold my-auto text-center">Ooops!</h1>
                            <h1 className="w-full text-xl font-bold my-auto text-center">Game Over</h1>
                        </div>
                        <div className="w-[10%] h-[40%] my-auto rounded hover:bg-gray-400 transition duration-300 cursor-pointer text-center" onClick={this.props.onClose}>x</div>
                    </div>
                    <div className='w-full h-[40%] flex items-center'>
                        <div className='w-full my-auto'>
                            <p className='w-[100%] text-sm text-center'>Word of the day</p>
                            <p className='w-[100%] text-3xl text-center'></p>
                            <p className='w-[100%] text-pink text-3xl text-center font-bold'>{this.props.answer}</p>
                        </div>
                    </div>
                    <div className='h-[35%] py-4'>
                        <p className='w-[100%] text-base text-center'>Look on the bright side, you learned a new word.</p>
                        <p className='w-[100%] text-base text-center'>🎉🎉🎉</p>
                    </div>
                </div>
            </div>
        );
    }

}

export default GameOverModal;
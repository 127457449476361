import heart from '../../Style/heart.png'
import coffee from '../../Style/coffee.png'

function Footer(props) {
  let textColor = props.isDarkMode ? 'text-white' : '';
  return (
    <div className='h-[6%] min-h-[40px]'>
      <div className="w-[15%] min-w-[300px] mx-auto flex">
        <img className='w-[10%] h-[40px] object-contain' draggable='false' src={heart} alt="heart" />
        <p className='w-[40%] my-auto text-orange text-center font-bold'> SYNORDLE ?</p>
        <p className='w-[5%] my-auto text-center'> - </p>
        <a className='w-[45%] h-[40px] flex' href="https://www.buymeacoffee.com/synordle">
          <p className={`w-[75%] my-auto text-right ${textColor} pr-4 underline`}> Buy me a </p>
          <div className='flex items-center'>
            <img className='max-w-[30px] max-h-[30px] object-contain' draggable='false' src={coffee} alt="coffee" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Footer;

import '../../Style/shake.css';

const N = 5;
const inputArray = Array(N).fill(Array.from(Array(N).keys()));

function InputGrid(props) {
  return (
    <div className={props.isShaking === true ? 'h-[45%] w-[15%] min-h[280px] min-w-[300px] mx-auto my-auto flex justify-center shake' : 'h-[40%] w-[15%] min-h[280px] min-w-[300px] mx-auto my-auto flex justify-center'}>
      <div className="w-full my-auto grid grid-cols-1 gap-y-3">
        {inputArray.map((items, index) => {
          return (
            <div className='grid grid-cols-5 text-center text-4xl font-bold justify-items-center' key={index}>
              {items.map((subItems, sIndex) => {
                return <InputDiv rows={props.rows} inputRow={props.inputRow} index={index} sIndex={sIndex} answerWord={props.answerWord} />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function InputDiv(props) {
  let rows = props.rows;
  let inputRow = props.inputRow;
  let index = props.index;
  let sIndex = props.sIndex;
  if (rows.length === 0 || rows.length - 1 < index || rows[index].length - 1 < sIndex)
    return <GrayInputDiv sIndex={sIndex} index={index} letter='' />;
  let answerWord = props.answerWord;
  let guessLetter = rows[index][sIndex];
  if (inputRow <= index) {
    return <GrayInputDiv sIndex={sIndex} index={index} letter={guessLetter} />;
  } else if (guessLetter === answerWord.charAt(sIndex)) {
    return <GreenInputDiv sIndex={sIndex} index={index} letter={guessLetter} />;
  } else if (answerWord.includes(guessLetter)) {
      return handleOrangeInputDiv(props);
      //return <OrangeInputDiv sIndex={sIndex} index={index} letter={guessLetter} />;
  } else {
    return <DarkGrayInputDiv sIndex={sIndex} index={index} letter={guessLetter} />;
  }
}

function handleOrangeInputDiv(props) {
  let rows = props.rows;
  let answerWord = props.answerWord;
  let index = props.index;
  let sIndex = props.sIndex;
  let guessLetter = rows[index][sIndex];
  let letterPositions = findLetterPositions(answerWord, guessLetter);
  let guessLetterPositions = findLetterPositions(rows[index], guessLetter);
    let length = Math.min(letterPositions.length, guessLetterPositions.length);
    for(let i = 0; i < length; i++) {
      if(guessLetterPositions[i] === sIndex) {
        return <OrangeInputDiv sIndex={sIndex} index={index} letter={guessLetter} />
      }
    }
    return <DarkGrayInputDiv sIndex={sIndex} index={index} letter={guessLetter} />
}

function findLetterPositions(word, letter) {
  const positions = [];
  for (let i = 0; i < word.length; i++) {
    if (word[i] === letter) {
      positions.push(i);
    }
  }
  return positions;
}

function OrangeInputDiv(props) {
  let index = props.index;
  let sIndex = props.sIndex;
  let letter = props.letter;
  return <div className='w-12 h-12 border-1 border-dark-gray  bg-orange text-white' key={index + '-' + sIndex}>{letter}</div>;
}

function GreenInputDiv(props) {
  let index = props.index;
  let sIndex = props.sIndex;
  let letter = props.letter;
  return <div className='w-12 h-12 border-1 border-dark-gray bg-green text-white' key={index + '-' + sIndex}>{letter}</div>;
}

function GrayInputDiv(props) {
  let index = props.index;
  let sIndex = props.sIndex;
  let letter = props.letter;
  return <div className='w-12 h-12 border-1 border-dark-gray bg-light-gray' key={index + '-' + sIndex}>{letter}</div>;
}

function DarkGrayInputDiv(props) {
  let index = props.index;
  let sIndex = props.sIndex;
  let letter = props.letter;
  return <div className='w-12 h-12 border-1 border-dark-gray bg-dark-gray text-white' key={index + '-' + sIndex}>{letter}</div>;
}

export default InputGrid;

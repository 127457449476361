import '../../Style/App.css';

function HintWord(props) {
  let exampleSentence = props.exampleSentence;
  let synonym = props.synonym;
  let startIndex = exampleSentence.indexOf(synonym);
  let endIndex = startIndex + synonym.length;
  let sentenceTextColor = props.isDarkMode ? "text-white" : "text-dark-gray";
  let definitionTextColor = props.isDarkMode ? "text-white" : "text-gray-700";
  return (
      <div className='h-[18%] w-[23%] min-w-[380px] mx-auto leading-4 text-center pt-4'>
        <p className={`h-[50%] ${sentenceTextColor} italic Times text-center mx-auto select-none notranslate`}>"{exampleSentence.substring(0, startIndex)}<span className="text-pink">{synonym}</span>{exampleSentence.substring(endIndex, exampleSentence.length)}"</p>
        <p className="h-[5%]"></p>
        <p className={`h-[45%] ${definitionTextColor} text-sm leading-4 select-none notranslate`}>{props.definition}</p>
      </div>
  );
}

export default HintWord;

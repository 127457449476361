import React from 'react';

class QuestionMarkModal extends React.Component {
    handleOverlayClick = (event) => {
        // Only close the modal if the user clicked on the overlay, not on the modal content.
        if (event.target === event.currentTarget) {
            this.props.onClose();
        }
    }

    render() {
        let theme = this.props.isDarkMode ? "bg-darkmode text-white" : "bg-white";
        return (
            <div className="fixed inset-0 z-10 flex items-center justify-center">
                <div className="absolute inset-0 bg-gray-500 opacity-75 z-40" onClick={this.handleOverlayClick} />
                <div className={`w-[20%] h-[60%] min-h[350px] min-w-[300px] ${theme} rounded-lg px-4 py-3 z-50`}>
                    <h1 className="h-[10%] text-xl font-bold mx-auto text-center">How to Play</h1>
                    <h2 className="h-[8%] Times mx-auto select-none notranslate">Guess today's word in 5 tries.</h2>
                    <li className="h-[8%] Times text-xs mx-auto pl-2 select-none notranslate">You have an example sentence containing a pink word.</li>
                    <li className="h-[8%] Times text-xs mx-auto pl-2 select-none notranslate">You have the definition right below the example sentence.</li>
                    <li className="h-[8%] Times text-xs mx-auto pl-2 select-none notranslate">The goal is to find synonym of the pink word.</li>
                    <li className="h-[8%] Times text-xs mx-auto pl-2 select-none notranslate">Colors will guide you to the correct word.</li>
                    <div className="w-full h-[40%] mx-auto">
                    <div className='w-full h-[18%]'>
                            <div className='grid grid-cols-7 gap-x-0.5 justify-items-center text-center text-2xl font-bold'>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>M</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>O</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>U</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>S</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-dark-gray text-white'>E</div>
                            </div>
                        </div>
                        <p className='w-full h-[15%] text-xs py-1'><span className='font-bold text-sm'>E</span> is not in the word.</p>
                        <div className='w-full h-[18%]'>
                            <div className='grid grid-cols-7 gap-x-0.5 justify-items-center text-center text-2xl font-bold'>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>L</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>I</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-orange text-white'>M</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>I</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>T</div>
                            </div>
                        </div>
                        <p className='w-full h-[15%] text-xs py-1'><span className='font-bold text-sm'>M</span> should be at another position.</p>
                        <div className='w-full h-[18%]'>
                            <div className='grid grid-cols-7 gap-x-0.5 justify-items-center text-center text-2xl font-bold'>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-green text-white'>A</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>B</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>I</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>D</div>
                                <div className='w-9 h-9 border-1 border-dark-gray bg-light-gray text-black'>E</div>
                            </div>
                        </div>
                        <p className='w-full h-[15%] text-xs py-1'>You found where exactly <span className='font-bold text-sm'>A</span> is.</p>
                    </div>
                    <div className="h-[10%] flex items-center text-lg">
                        <div className="w-full bg-pink rounded hover:bg-gray-400 transition duration-300 text-white cursor-pointer text-center" onClick={this.props.onClose}>Got it</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuestionMarkModal;

function ErrorComponent(props) {
  return (
      <div className='h-[12%] w-[23%] min-w-[380px] mx-auto py-8'>
        <AnswerDiv showError={props.showError}/>
        </div>
  );
}

function AnswerDiv(props) {
  if(props.showError === true) {
    return <div style={styles.popup} className='rounded-lg text-pink font-bold'>
        <p>{"The word is not in the dictionary!"}</p>
      </div>
  } else {
    return <div className="w-1/2 mx-auto text-2xl"></div>
  }
}

const styles = {
  popup: {
    padding: '10px',
    'min-width': '300px',
    'text-align': 'center'
  },
};

export default ErrorComponent;

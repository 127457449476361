import React, {Suspense} from 'react';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import PrivacyPolicyAndTermsOfUse from './PrivacyPolicyAndTermsOfUse';
import QuestionMarkModal from './Body/QuestionMarkModal';
import SettingsModal from './Body/SettingsModal';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isQuestionMarkModalOpen: false,
      isSettingsModalOpen: false,
      isDarkMode: false,
      showPrivacyPolicyAndTermsOfUse: false
    };
  }

  render() {
    if(this.state.showPrivacyPolicyAndTermsOfUse) {
      return <PrivacyPolicyAndTermsOfUse closePrivacyPolicyAndTermsOfUserPage={this.closePrivacyPolicyAndTermsOfUserPage} isDarkMode={this.state.isDarkMode}/>
    } else {
      return (
        <div className={this.state.isDarkMode ? "h-full bg-darkmode" : "h-full"}>
          <Header onQuestionMarkClick={this.handleQuestionMarkClick} onSettingsClick={this.handleSettingsClick} ></Header>
          {this.state.isQuestionMarkModalOpen && <Suspense fallback={<div className='w-full h-full text-center'>Loading...</div>}>
          <QuestionMarkModal onClose={this.handleQuestionClose} isDarkMode={this.state.isDarkMode}/>
      </Suspense>}
      {this.state.isSettingsModalOpen && <Suspense fallback={<div className='w-full h-full text-center'>Loading...</div>}>
          <SettingsModal onClose={this.handleSettingsClose} onDarkModeClick={this.handleDarkModeClick} isDarkMode={this.state.isDarkMode}
          showPrivacyPolicyAndTermsOfUserPage={this.showPrivacyPolicyAndTermsOfUserPage}/>
      </Suspense>}
          <Body isDarkMode={this.state.isDarkMode}></Body>
          <Footer isDarkMode={this.state.isDarkMode}></Footer>
        </div>
      );
    }
  }

  componentDidMount() {
    let isDarkModePreference = localStorage.getItem('isDarkMode');
    if (isDarkModePreference !== null) {
      if(isDarkModePreference === 'true')
        this.setState({isDarkMode: true})
      else
        this.setState({isDarkMode: false})
    } else {
      localStorage.setItem('isDarkMode', this.state.isDarkMode);
    }
  }

  handleQuestionMarkClick = () => {
    this.setState({ isQuestionMarkModalOpen: true });
  }

  handleQuestionClose = () => {
    this.setState({ isQuestionMarkModalOpen: false });
  }


  handleSettingsClick = () => {
    this.setState({ isSettingsModalOpen: true });
  }

  handleSettingsClose = () => {
    this.setState({ isSettingsModalOpen: false });
  }

  handleDarkModeClick = () => {
    localStorage.setItem('isDarkMode', !this.state.isDarkMode);
    this.setState({ isDarkMode: !this.state.isDarkMode });
  }


  showPrivacyPolicyAndTermsOfUserPage = () => {
    this.setState({ showPrivacyPolicyAndTermsOfUse: true });
  }

  closePrivacyPolicyAndTermsOfUserPage = () => {
    this.setState({ showPrivacyPolicyAndTermsOfUse: false });
  }

}

export default App;

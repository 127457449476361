import React from 'react';
import tweeter from '../../Style/tweeter.png'
import gmail from '../../Style/gmail.png'
import { Switch } from '@mui/material';

class QuestionMarkModal extends React.Component {
    handleOverlayClick = (event) => {
        // Only close the modal if the user clicked on the overlay, not on the modal content.
        if (event.target === event.currentTarget) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <div className="fixed inset-0 z-10 flex items-center justify-center">
                <div className="absolute inset-0 bg-gray-500 opacity-75 z-40" onClick={this.handleOverlayClick} />
                <div className={this.props.isDarkMode ? "w-[20%] h-[30%] min-h-[300px] min-w-[250px] bg-darkmode text-white rounded-lg px-2 py-2 z-50 divide-y" : "w-[20%] h-[30%] min-h-[300px] min-w-[250px] bg-white rounded-lg px-2 py-2 z-50 divide-y"}>
                    <div className='h-[20%] flex'>
                        <div className="w-[10%]" />
                        <h1 className="w-[80%] text-xl font-bold my-auto text-center">Settings</h1>
                        <div className="w-[10%] h-[50%] my-auto rounded hover:bg-gray-400 transition duration-300 cursor-pointer text-center" onClick={this.props.onClose}>x</div>
                    </div>

                    <div className='h-[17%] flex'>
                        <h1 className="w-[70%] text-lg mx-auto text-left py-3">Dark Mode:</h1>
                        <Switch size="small" checked={this.props.isDarkMode} onChange={this.props.onDarkModeClick} className='w-[30%] max-w-[40px] max-h-[40px] cursor-pointer mx-auto my-auto' />
                    </div>
                    <div className='h-[17%] flex'>
                        <h1 className="w-[70%] text-lg mx-auto text-left py-3">Feedback:</h1>
                        <a className='w-[30%] max-w-[40px] max-h-[40px] mx-auto cursor-pointer my-auto flex items-center' href={`mailto:synordle.game@gmail.com?subject=Synordle%20Feedback&body=${this.getDeviceSummary()}`}>
                            <img className='w-full max-w-[40px] max-h-[40px] object-contain py-2' draggable='false' src={gmail} alt="gmail"/>
                        </a>
                    </div>
                    <div className='h-[17%] flex'>
                        <h1 className="w-[70%] text-lg mx-auto text-left py-3">Social Media:</h1>
                        <a className='w-[30%] max-w-[40px] max-h-[40px] mx-auto cursor-pointer my-auto flex items-center' href={'https://twitter.com/synordle'}>
                            <img className='w-full max-w-[40px] max-h-[40px] object-contain py-2' draggable='false' src={tweeter} alt="tweeter" />
                        </a>
                    </div>
                    <div className='h-[19%] flex items-center py-4'>
                        <p className='w-[100%] text-xs text-center'>
                            Special thanks to
                            <a className='underline underline-offset-2 font-bold' href="https://twitter.com/powerlanguish"> Josh Wardle </a>
                            for inspiring us with his game
                            <a className='underline underline-offset-2 font-bold' href="https://www.powerlanguage.co.uk/wordle"> Wordle</a>
                            .
                        </p>
                    </div>
                    <div className='h-[10%] flex items-center py-4'>
                        <p className="w-[100%] text-center text-xs underline underline-offset-4 cursor-pointer" onClick={this.props.showPrivacyPolicyAndTermsOfUserPage}>Privacy policy & Terms of Use</p>
                    </div>
                </div>
            </div>
        );
    }

    getDeviceSummary = () => {
        const isMobile = window.innerWidth <= 768;
        const isTablet = window.innerWidth > 768 && window.innerWidth <= 1023;
        const platform = isMobile ? isTablet ? 'Tablet' : 'Mobile' : 'Desktop';
        const browser = navigator.userAgent;
        const screenResolution = `${window.screen.width} x ${window.screen.height}`;
        const viewportSize = `${window.innerWidth} x ${window.innerHeight}`;
        const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        return '%0D%0A%0D%0A%0A----------------------------------------%0ADevice%20Summary%3A%0APlatform%3A'.concat(platform, '%0ABrowser%3A', browser, '%0AScreen%20Resolution%3A',
            screenResolution, '%0AViewport%20Size%3A', viewportSize, '%0ATimezone%3A', timezone);
    }
}

export default QuestionMarkModal;
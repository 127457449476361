import questionMark from '../../Style/questionMark.png'
import settings from '../../Style/settings.png'

function Header(props) {
  return (
    <div className='h-[10%] w-screen flex border-gray-300 border-b-1 pt-8'>
      <div className='w-[5%] min-w-[80px] ml-2'/>
      <p className='w-[86] mx-auto font-sans tracking-widest font-black text-3xl text-orange text-center'>Synordle</p>
      <img className='w-[2.5%] min-w-[40px] h-[40px] p-1 float-right cursor-pointer object-contain hover:bg-gray-300 rounded-full transition duration-500' 
      draggable='false' src={questionMark} alt="questionMark" onClick={props.onQuestionMarkClick}/>
      <img className='w-[2.5%] min-w-[40px] h-[40px] p-1 mr-2 float-right cursor-pointer object-contain hover:bg-gray-300 rounded-full transition duration-500' 
      draggable='false' src={settings} alt="questionMark" onClick={props.onSettingsClick}/>
    </div>
  );
}

export default Header;
